import '@babel/polyfill'
import 'whatwg-fetch'
import 'labs-js/src/common/custom_polyfills.js'
import Vue from 'vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'element-ui/lib/theme-chalk/display.css'
import locale from 'element-ui/lib/locale/lang/en'
import Nav from '../src/nav/components/App'

const element = document.getElementById('topNav')
const user = element.dataset.user
const market = element.dataset.market == 'true'
const atp = element.dataset.atp == 'true'
const luxuser = element.dataset.luxuser == 'true'

Vue.use(ElementUI, {locale})

const navVue = new Vue({
  el: element,
  components: {Nav},
  template: `<Nav
    ref="nav"
    current-user="${user}"
    :show-market="${market}"
    :show-atp="${atp}"
    :lux-user="${luxuser}"
  />`,
})

window.setNavFromHash = function() {
  navVue.$refs.nav.setNavFromHash()
}
