
<template lang="html">
  <el-row id="mainNav" >
    <el-col :span="4" class="logo hidden-xs-only">
      <a href="/">
        <img
          class="hidden-sm-and-down hide-max-767"
          src="https://staging.luxresearchinc.com/assets/icons/lux-icon-white-727e3bb9afd9be37debd1e542ce19a0f9b20e765f3be674b762fc526370234de.png"
          alt="Lux Logo"
        >
        <img
          class="hidden-md-and-up hide-max-767 lux_orange_icon"
          src="https://staging.luxresearchinc.com/assets/icons/lux-icon-white-727e3bb9afd9be37debd1e542ce19a0f9b20e765f3be674b762fc526370234de.png"
          alt="Lux Logo"
        >
      </a>
    </el-col>
    <el-col :sm="20" :xs="24">
      <el-row id="navItems" type="flex" justify="space-between">
        <el-col id="navLeft" :xs="13" :md="16">
          <el-dropdown trigger="click" @visible-change="mobileMenuToggleEvents" class="mobile-menu-anchor-events item-padding">
            <span :class="eventsDropdownClasses">
              <span class="hide-min-768">
                <i v-if="showCloseIcon" class="glyphicon glyphicon-remove"></i>
                <i v-else class="fa fa-bars"></i>
              </span>
              <span class="hide-max-767">
                Events
                <span class="glyphicon glyphicon-menu-down" aria-hidden="true" />
              </span>
            </span>
            <el-dropdown-menu slot="dropdown" class="events-dropdown" ref="eventsDropdown">
              <el-dropdown-item>
                <a :href="webinarsPath">Webinars</a>
              </el-dropdown-item>
              <el-dropdown-item>
                <a :href="industryEventsPatch">Industry Events</a>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <a v-if="currentUser" :href="ideathonsPath" class="hidden-md-and-down item-padding">Ideathons</a>
          <a
            href="//www.luxresearchinc.com/blog"
            target="_blank"
            class="hidden-sm-and-down item-padding"
          >
            Blogs <span class="glyphicon glyphicon-new-window" />
          </a>
          <a
            href="/research/technology"
            :class="showMarket ? 'hidden-md-and-down item-padding' : 'hidden-sm-and-down item-padding'"
          >
            The Lux 299
          </a>
          <a
            v-if="showMarket"
            href="/labs/market"
            class="hidden-md-and-down item-padding"
          >
            Market Map Creator
          </a>
          <el-dropdown trigger="click" @visible-change="mobileMenuToggle" class="mobile-menu-anchor item-padding">
            <span :class="moreDropdownClasses">
              <span class="hide-min-768">
                <i v-if="showCloseIcon" class="glyphicon glyphicon-remove"></i>
                <i v-else class="fa fa-bars"></i>
              </span>
              <span class="hide-max-767">
                More
                <span class="glyphicon glyphicon-menu-down" aria-hidden="true" />
              </span>
            </span>
            <el-dropdown-menu slot="dropdown" class="more-dropdown" ref="moreDropdown">
              <el-dropdown-item class="hidden-sm-and-up">
                <a :href="webinarsPath">Webinars</a>
              </el-dropdown-item>
              <el-dropdown-item class="hidden-md-and-up">
                <a href="//www.luxresearchinc.com/blog" target="_blank">
                  Blogs
                </a>
              </el-dropdown-item>
              <el-dropdown-item>
                <a href="/research/technology">
                  The Lux 299
                </a>
              </el-dropdown-item>
              <el-dropdown-item v-if="showMarket">
                <a href="/labs/market">Market Map Creator</a>
              </el-dropdown-item>
              <el-dropdown-item v-if="!currentUser" class="contact-button-container">
                <a href="http://www.luxresearchinc.com/about-us/contact-us" class="hide-min-768 btn btn-default border-orange-button">
                  Contact Us
                </a>
              </el-dropdown-item>
              <!-- <el-dropdown-item class="hidden-sm-and-up">
                <span @click="analystPopover = true">Ask An Analyst</span>
              </el-dropdown-item> -->
            </el-dropdown-menu>
          </el-dropdown>
          <a href="/" class="logo_link">
            <img
            class="hidden-md-and-up hide-min-768 lux_orange_icon"
            src="https://staging.luxresearchinc.com/assets/icons/lux-icon-white-727e3bb9afd9be37debd1e542ce19a0f9b20e765f3be674b762fc526370234de.png"
            alt="Lux Logo"
          >
          </a>
        </el-col>
        <el-col v-if="currentUser" id="navRight" :xs="11" :md="8">
          <!-- <el-popover
            v-model="analystPopover"
            content=" "
            popper-class="analyst-popover"
            :visible-arrow="false"
          >
            <span
              slot="reference"
              :class="analystToggleClasses"
            >
              Ask An Analyst
            </span>
          </el-popover> -->
          <el-popover
            content=" "
            popper-class="my-lux-popover"
            placement="bottom-end"
            :visible-arrow="false"
            @show="onMyLuxShowPopup"
            @hide="onMyLuxHidePopup"
          >
            <div slot="reference" :class="showCloseIcon ? 'hide-user my-lux-toggle' : 'my-lux-toggle'" class="my-lux-toggle">
              <span class="my-lux-link">
                <span class="userIntials">{{ currentUser }}</span>
                <span class="hide-max-991 my-lux-anchor-text">
                  My Lux <span class="glyphicon glyphicon-menu-down" aria-hidden="true" />
                </span>
              </span>
            </div>
          </el-popover>
        </el-col>
        <el-col v-else id="navRight" :span="12">
          <a href="http://www.luxresearchinc.com/about-us/contact-us" class="hide-max-767 btn btn-default border-orange-button">
            Contact Us
          </a>
          <a :href="signInAndReturnPath" class="btn btn-default orange-button">Sign In</a>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <el-autocomplete
            ref="autocomplete"
            v-model="searchText"
            placeholder="Search Lux Research..."
            class="input-with-select top-search-bar"
            :fetch-suggestions="getAutocompleteResults"
            hide-loading
            @change="updateSearch"
            @select="goToResult"
          >
            <el-select
              slot="prepend"
              v-model="searchType"
              placeholder="Select"
              class="search-bar-select"
              @change="updateSearch"
            >
              <el-option label="All" value="all" />
              <el-option label="Companies" value="companies" />
              <el-option label="Technologies" value="tech_pages" />
              <el-option label="Ideathons" value="ideathon" />
              <el-option v-if="showAtp" label="Automated Tech Hub" value="atp" />
              <el-option v-if="currentUser" label="News" value="news" />
            </el-select>
            <el-button slot="append" @click="submitSearch">
              <span class="glyphicon glyphicon-search" />
              <span class="hidden-xs-only">Search</span>
            </el-button>
            <a
              slot-scope="{ item }"
              :href="item.url"
              class="autosuggest-item"
            >
              <span>{{ item.name }}</span>
              <span class="item_type">{{ item.type }}</span>
            </a>
          </el-autocomplete>
        </el-col>
      </el-row>
    </el-col>
  </el-row>
</template>

<script>
const cleanupQuotes = string => {
  return string
    .replace(/[\u2018\u2019]/g, "'")
    .replace(/[\u201C\u201D]/g, '"');
}

const getHashObj = () => {
  if (window.location.pathname != '/search') return null
  let hash = decodeURIComponent(window.location.hash.substr(1))
  if (hash.length == 0) return null
  else return JSON.parse(hash)
}

export default {
  props: {
    currentUser: {type: String, default: ''},
    showMarket: {type: Boolean, default: false},
    showAtp: {type: Boolean, default: false},
    luxUser: {type: Boolean, default: false},
  },
  data() {
    return {
      searchText: '',
      searchType: 'all',
      analystPopover: false,
      typeWidths: {
        'all': '65px',
        'companies': '120px',
        'tech_pages': '135px',
        'atp': '115px',
        'news': '85px',
      },
      showCloseIcon: false,
    }
  },
  computed: {
    webinarsPath() {
      return this.currentUser
        ? '/webinars'
        : '//www.luxresearchinc.com/news-and-events/webinars'
    },
    ideathonsPath() {
      return this.currentUser
        ? '/ideathons'
        : '//www.luxresearchinc.com/ideathons'
    },
    industryEventsPatch() {
      return 'https://www.luxresearchinc.com/events-and-webinars/events/'
    },
    signInAndReturnPath() {
      return '/users/sign_in?return_to=' + encodeURIComponent(window.location.href)
    },
    moreDropdownClasses() {
      if (this.showMarket) return 'more-dropdown-toggle hidden-lg-and-up'
      else return 'more-dropdown-toggle hidden-md-and-up'
    },
    eventsDropdownClasses() {
      return 'events-dropdown-toggle'
    },
    // analystToggleClasses() {
    //   if (this.analystPopover) return 'hidden-xs-only analyst-button active_link'
    //   else return 'hidden-xs-only analyst-button'
    // }
  },
  watch: {
    searchType: function(val) {
      $('.search-bar-select .el-input').width(
        this.typeWidths[val]
      )
      $('.search-bar-select .el-input__inner').blur()
    },
  },
  mounted() {
    this.setNavFromHash()
    $('#navLeft > a:not(.logo-link)').each(function() {
      if (window.location.pathname == this.getAttribute('href')) {
        this.classList.add('active_link')
      }
    })
    if (window.location.pathname != '/search') {
      this.$refs.autocomplete
        .$refs.input.$el.addEventListener('keyup', e => {
          if (e.keyCode == 13 && this.searchText) this.submitSearch()
        })
    }
  },
  methods: {
    onMyLuxShowPopup(){
      jQuery('.my-lux-toggle .my-lux-link').toggleClass('active_link')
      jQuery('.my-lux-toggle .glyphicon' ).attr('class', 'glyphicon glyphicon-menu-up' )
    },
    onMyLuxHidePopup(){
      jQuery('.my-lux-toggle .my-lux-link').toggleClass('active_link')
      jQuery('.my-lux-toggle .glyphicon' ).attr('class', 'glyphicon glyphicon-menu-down' )
    },
    mobileMenuToggle(is_visible){
      jQuery('.mobile-menu-anchor').toggleClass('active_link')
      jQuery('.mobile-menu-anchor .glyphicon' ).attr('class', 'glyphicon ' + ( (is_visible) ? 'glyphicon-menu-up' : 'glyphicon-menu-down' ) )

      if(is_visible){
        jQuery('body').addClass('prevent-croll');
      }else{
        jQuery('body').removeClass('prevent-croll');
      }

      this.showCloseIcon = is_visible
    },
    mobileMenuToggleEvents(is_visible){
      jQuery('.mobile-menu-anchor-events').toggleClass('active_link')
      jQuery('.mobile-menu-anchor-events .glyphicon' ).attr('class', 'glyphicon ' + ( (is_visible) ? 'glyphicon-menu-up' : 'glyphicon-menu-down' ) )

      if(is_visible){
        jQuery('body').addClass('prevent-croll');
      }else{
        jQuery('body').removeClass('prevent-croll');
      }

      this.showCloseIcon = is_visible
    },
    submitSearch() {
      this.searchText ||= ''
      if (this.searchType == 'atp') {
        if (this.searchText.length) {
          window.location = '/research/technology/lti:' + this.searchText
        } else {
          window.location = '/research/technology/search'
        }
      } else if (window.location.pathname == '/search') {
        window.updateSearchFromNavbar(this.searchType, this.searchText)
      } else {
        const searchData = {}
        if (this.searchText.length) searchData.keywords = cleanupQuotes(this.searchText)
        if (this.searchType != 'all') searchData.search = this.searchType
        if (Object.keys(searchData).length > 0) {
          window.location = '/search#' + encodeURIComponent(JSON.stringify(searchData))
        } else {
          window.location = '/search'
        }
      }
    },
    getAutocompleteResults(q, cb) {
      if (window.location.pathname != '/') return cb([])
      if (q.trim().length == 0) return cb([])
      fetch('/search/auto_complete?keywords=' + q + '&type=' + this.searchType)
        .then(res => res.json())
        .then(data => cb(data))
    },
    updateSearch() {
      if (window.location.pathname == '/search' && this.searchType != 'atp') {
        window.updateSearchFromNavbar(this.searchType, this.searchText)
      }
    },
    goToResult({url}) {
      window.location = url
    },
    setNavFromHash() {
      if (window.location.pathname.startsWith('/research/technology/search')) {
        this.searchType = 'atp'
        this.searchText = ''
      } else if (window.location.pathname.startsWith('/research/technology/lti:')) {
        this.searchType = 'atp'
        this.searchText = window.location.pathname.split('lti:')[1]
      } else {
        const hashObj = getHashObj()
        if (hashObj && Object.keys(hashObj).length > 0) {
          this.searchType = hashObj.search || 'all'
          this.searchText = hashObj.keywords || hashObj.q || ''
        } else {
          this.searchType = 'all'
          this.searchText = ''
        }
      }
    },
  },
}
</script>

<style lang="scss">
  .logo_link{
    padding: 0 !important;
    border: 0 !important;
  }
  .my-lux-popover {
    top: 54px !important;
  }

  #mainHeader {
    border-bottom: 1px solid #cccccc;
    .container {
      padding-left: 0;
      padding-right: 0;
    }
  }

  #mainNav {
    display: flex;
    padding: 16px 0;
    @media (max-width: 767px) {
      padding-left: 16px;
      padding-right: 16px;
    }

    .my-lux-toggle .my-lux-link{
      display: flex;
      .my-lux-anchor-text{
        padding: 9px 0 0 8px;
      }
    }

    .my-lux-toggle .my-lux-link .glyphicon-menu-down {
      padding-bottom: 4px;
      font-weight: bold;
    }

    .active_link{
      font-weight: bold;
      border-bottom: 2px solid #333;
      &, .glyphicon {
        color: #333;
      }

    }

    .my-lux-toggle .my-lux-link.active_link {
        border-bottom: 0;
        .my-lux-anchor-text{
          border-bottom: 2px solid #333;
        }
        &, .glyphicon {
          color: #333;
        }
      }

    .my-lux-toggle .glyphicon{
      vertical-align: middle;
      padding-left: 5px;
    }

    #navItems {
      color: #e4e4e4;
    }

    a, span {
      color: #333;
      text-decoration: none;
      cursor: pointer;
      font-weight: bold;
      &.glyphicon-new-window {
        padding-left: 8px;
      }
    }

    span:before{
      font-weight: 100;
    }

    a:hover,span:hover {
      &, .glyphicon {
        color: #000000;
      }
    }

    a:active {
      border-bottom: 2px solid #000000;
      display: inline;
      color: #000000;
    }

    #navLeft {
      display: flex;
      gap: 24px;
      align-items: center;
      .glyphicon.glyphicon-new-window{
        -webkit-text-stroke: 1px white;
      }

      & > a.logo-link {
        border-bottom: 0 !important;
        .xs-logo { height: 36px; }
      }
      & > a:not(:active),
      .mobile-menu-anchor:not(.active_link) {

      }

      .mobile-menu-anchor.el-dropdown{

      }

      .item-padding{
        padding-bottom: 6px;
      }

      a:active,.active_link {
        padding-bottom: 4px;
      }
    }

    #navRight {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 24px;

      .my-lux-toggle:hover {
        font-weight: bold;
      }

      .my-lux-toggle {
        display: flex;
        align-items: center;
        gap: 10px;
      }

      /* .analyst-button {
        padding: 5px 8px;
      } */

      .userIntials {
        height: 36px;
        width: 36px;
        font-weight: bold;
        font-size: 12px;
        line-height: 12px;
        border: 0;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #FFFFFF;
        &:hover{background: #b83d00;}
      }

      .btn-contact { color: #000; }
      .btn.btn-default.save {
        margin-bottom: 0;
      }
    }

    .logo {
      border-right: 1px solid #adadad;
      display: flex;
      padding-right: 30px;
      margin-right: 30px;

      a {
        margin: auto;
        margin-right: 0;
        border-bottom: 0;
      }
      img {
        width: 134px;
        @media (max-width: 1199px) { width: 101px; }
        @media (max-width: 991px) { width: 64px; }
      }
    }

    .el-input__inner {
      border: none;
      border-radius: 45px 0 0 45px;
      background: #E6E6E6;
      color: #333;
      &:hover { background: #d9d9d9; }
      &:focus{border: 1.5px solid #4d842d; background:  #FFF; padding-left: 13.5px; }
      @media (min-width: 768px) {
        border-radius: 0;
      }
    }

    .search-bar-select{
      .el-input__inner {
        &:focus{border: 1.5px solid #4d842d; background:  #e6e6e6; padding-left: 15px;}
      }
    }

    .el-input__inner::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #4e4e4e;
      opacity: 1; /* Firefox */
    }

    .el-input__inner:-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: #4e4e4e;
    }

    .el-input__inner::-ms-input-placeholder { /* Microsoft Edge */
      color: #4e4e4e;
    }

    .input-with-select.top-search-bar {
      margin-top: 16px;
      width: 100%;

      .el-select.search-bar-select {
        background: #FFF;
        border-right: 1px solid #4e4e4e;
        color: #4e4e4e;
        font-weight: bold;
        .el-input__suffix{
          background:  transparent !important;
          .el-input__suffix-inner{
            background:  transparent !important;
          }
        }
        .el-input__inner {
          border: none;
          color: #4e4e4e;
          @media (min-width: 768px) {
            border-radius: 45px 0 0 45px;
          }
        }



        i {
          color: #4e4e4e;
          font-weight: bold;
          background: transparent !important;
        }

        .el-input {
          width: 65px;
          transition: width 0.2s linear;
          &:hover * { background: #d9d9d9; }
          &.is-focus{
            .el-input__inner{
              border: 1.5px solid #4d842d;
              background: #fff;
              padding-left: 13.5px;
            }

            .el-select__caret{
              background: #fff;
            }
          }
        }
      }

      .el-input-group__prepend {
        border: none;
        @media (max-width: 767px) {
          display: none;
        }
      }

      .el-input-group__append {
        background: #4D842D;
        border-radius: 0 45px 45px 0;
        border: 0px;
        padding: 0 12px;
        &:hover{
          background: #477a29 ;
        }
        &:active, &:focus {
          background: #416f26;
        }
        .el-button {
          padding: 12px 16px 12px 12px;
          margin: -12px;
        }
        span {
          color: #FFF;
          .glyphicon { top: 2px; }
          .hidden-xs-only {
            font-weight: bold;
            padding-left: 10px;
          }
        }
      }
    }
  }

  #mainHeader.version2 {
    background-color: #333333;
    border-bottom: 0px;
    .container {
      #mainNav {
        a {
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.005em;
          color: rgba(255, 255, 255, 0.84);
        }

        .userIntials {
          background: #EDDC29;
          color: #333333 !important;
        }

        #navItems {
          color: rgba(255, 255, 255, 0.84);
        }

        .active_link {
          font-family: 'Montserrat' !important;
          font-style: normal !important;
          font-weight: 700 !important;
          font-size: 14px !important;
          line-height: 20px !important;
          letter-spacing: 0.005em !important;
          color: rgba(255, 255, 255, 0.84) !important;
          &, .glyphicon {
            color: rgba(255, 255, 255, 0.84);
          }
        }

        .el-input__inner {
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          background: #595959;
          color: #D9D9D9 !important;
          &::placeholder {
            color: #D9D9D9 !important;
          }
        }


        .el-input__suffix {
          color: #D9D9D9 !important;
        }

        span {
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.005em;
          color: rgba(255, 255, 255, 0.84);
        }

        .input-with-select.top-search-bar {
          .el-input-group__append {
            background: #EDDC29;

            span {
              font-family: 'Montserrat';
              font-style: normal;
              font-weight: 700;
              font-size: 14px;
              line-height: 20px;
              letter-spacing: 0.005em;
              color: #333333;
            }
          }
          .el-input-group__prepend {
            border: none;
            background: transparent !important;
            &, input {
              font-family: 'Montserrat';
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
              background: #595959;
              color: #D9D9D9 !important;
            }
          }
          .el-select.search-bar-select {
            background: transparent;
            &, i {
              color: #D9D9D9 !important;
            }
          }
        }
      }
    }
  }

  .autosuggest-item {
    color: #666666;
    font-weight: 100;
    line-height: 20px;
    letter-spacing: 0.005em;
    display: block;
    &:hover { text-decoration: none; color: #333;}
    span {
      display: block;
      width: 100%;
    }
    .item_type{
      color: #666666;
      font-size: 10px;
      text-transform: capitalize;
      letter-spacing: 0.01em;
    }
  }
  .more-dropdown {
    a, span {
      color: #000;
      text-decoration: none;
      cursor: pointer;
    }
  }
  .events-dropdown {
    a, span {
      color: #000;
      text-decoration: none;
      cursor: pointer;
    }
  }


@media (max-width:767px){

  .primary-footer {
    .footerLogo{width:94px;}

  }

  .primary-footer{
    padding: 0;
  }

  body{
    &.prevent-croll{
      height: 100%;
      overflow: hidden;
    }

    &.lux_user.admin_pinned{

      .my-lux-popover {
        top: 102px !important;
      }

      .el-dropdown-menu.more-dropdown{
        top: 102px !important;
      }
      .el-dropdown-menu.events-dropdown{
        top: 102px !important;
      }
    }
  }

  .el-dropdown-menu.more-dropdown{
    width: 100vw;
    height: 100vh;
    text-align: center;
    top: 66px !important;
    left: 0 !important;
    margin-top: 0 !important;
    padding-top: 0 !important;
    border-top: 1px solid #cccccc !important;
    border-radius: 0 !important;
    box-shadow: none;

    .el-dropdown-menu__item{
      display: inline-block;
      width: 100%;
      padding: 12px 20px;
      a{
        color:  #333;
      }
    }

  }

  .el-dropdown-menu.events-dropdown{
    width: 100vw;
    height: 100vh;
    text-align: center;
    top: 66px !important;
    left: 0 !important;
    margin-top: 0 !important;
    padding-top: 0 !important;
    border-top: 1px solid #cccccc !important;
    border-radius: 0 !important;
    box-shadow: none;

    .el-dropdown-menu__item{
      display: inline-block;
      width: 100%;
      padding: 12px 20px;
      a{
        color:  #333;
      }
    }

  }

  .hide-max-767{
    display: none;
  }

  .more-dropdown-toggle .fa-bars, .more-dropdown-toggle .glyphicon.glyphicon-remove{
    font-size: 20px;

  }

  .more-dropdown-toggle .glyphicon.glyphicon-remove{
    -webkit-text-stroke: 3px white;
  }

  .events-dropdown-toggle .fa-bars, .events-dropdown-toggle .glyphicon.glyphicon-remove{
    font-size: 20px;

  }

  .events-dropdown-toggle .glyphicon.glyphicon-remove{
    -webkit-text-stroke: 3px white;
  }

  .lux_orange_icon{
    width: 44px;
  }

  #mainNav{
    #navLeft{
      justify-content: inherit;
      .mobile-menu-anchor.el-dropdown{
        border-bottom: 0 !important;
      }
    }
  }

  .primary-footer{
    .footerLogo{
      margin: auto;
    }
    .col-md-6{
      text-align: center;
    }
    .col-xs-12{

    }

    hr{
      margin: 11px 0 16px 0;
    }
  }

  .hide-user{
    display: none !important;
  }
}

@media (min-width:768px){
  body{
    &.lux_user.admin_pinned{
      .my-lux-popover {
        top: 88px !important;
      }
    }
  }
  .logo {
    max-width: 94px;
    margin-left: 0 !important;
  }

  .hide-min-768{
    display: none;
  }

  .primary-footer{
    padding: 0 15px;
    .col-xs-12{
      width: 110px;
      padding-left: 0;
    }

    hr{ margin: 16px 0; }

    .footer-second-section{
      width: 492px;
      float: right;

      .col-md-6{
        float: left;
        width: 312px;
        padding: 0;
        .nav-pills{
          margin-top: 0;
          li a{
            font-size: 14px;
          }
        }
      }

      .col-md-6.social-icons-container{
        margin-left: 24px;
        margin-top: 0;
        float: left;
        padding: 0;
        width: 154px;
      }
    }

    .col-xs-12{
      float: left;
      display: block;
      width: 30%;
    }
  }
}

  @media (max-width:991px){
    .hide-max-991{
      display:  none;
    }
  }

  @media (min-width:992px){
    .logo {
      max-width: 131px;
    }
  }

  @media (min-width:1200px){
    .logo {
      max-width: 164px;
    }
  }

  .el-select-dropdown.el-popper[x-placement^=bottom]{
    margin-top: 4px;
    box-shadow: 0px 1px 18px rgba(0, 0, 0, 0.12), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.2);
  }

  .el-select-dropdown, .el-dropdown-menu, .el-autocomplete-suggestion{
    li{
      &:hover{
        a{ font-weight: 400; }
        background: #f3f3f3 !important;
      }
    }

    .popper__arrow{
      display: none !important;
    }
  }

  .more-dropdown{
    li.contact-button-container{
      &:hover{
        a{ font-weight: 700; }
        background: none !important;
      }
    }

  }
  .events-dropdown{
    li.contact-button-container{
      &:hover{
        a{ font-weight: 700; }
        background: none !important;
      }
    }

  }
  .el-autocomplete-suggestion{
    &.el-popper[x-placement^=bottom]{
      margin-top: 4px;
      box-shadow: 0px 1px 18px rgba(0, 0, 0, 0.12), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.2);
    }

    li{
      padding: 6px 20px !important;
      &:hover{
        a{ font-weight: 400; }
        background: #f3f3f3 !important;
      }
    }
  }

  .el-select-dropdown__item.hover{
    background: #f3f3f3;
    color:  #333;
  }

  .more-dropdown{
    .el-dropdown-menu__item{
      a{
        color:  #666666;
        &:hover, &:focus{
          color:  #333;
        }
      }

      &:hover, &:focus{
        background: #f3f3f3;
        color:  #333 !important;
      }
    }
  }

  .events-dropdown{
    .el-dropdown-menu__item{
      a{
        color:  #666666;
        &:hover, &:focus{
          color:  #333;
        }
      }

      &:hover, &:focus{
        background: #f3f3f3;
        color:  #333 !important;
      }
    }
  }

  .el-select-dropdown__item.selected{
    color: #4D842D !important;
  }

  .el-popper[x-placement^=bottom]{
    margin-top: 0;
  }

  .btn.orange-button{
    color: #fff !important;
    font-size: 14px;
    background: #c84200;
    padding: 8px 12px;
    border-radius: 45px;
    border: 0;
    text-transform: none;
    &:hover, &:focus{
      background: #b83d00;
    }

    &:active{
      background: #a83700;
      border-bottom: 0 !important;
    }
  }

  .btn.border-orange-button{
    color: #c84200 !important;
    font-size: 14px;
    background: #fff;
    border:  1px solid #c84200;
    padding: 8px 12px;
    border-radius: 45px;
    text-transform: none;
    &:hover, &:focus{
      background: #fbf0eb;
    }

    &:active{
      background: #f6e1d6;
      border:  1px solid #c84200;
      border-bottom: 1px solid #c84200 !important;
    }
  }

  .more-dropdown .btn.border-orange-button{
      width: 100%;
  }
  .events-dropdown .btn.border-orange-button{
      width: 100%;
  }
</style>
